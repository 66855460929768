<template>
  <v-card min-height="300" elevation="0">
    <v-card-text>
      <v-html-editor
        v-model="content"
      ></v-html-editor>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'HTML Editor'
    }
  },
  data: () => ({
    content: '',
  }),
}
</script>

<style lang="sass" type="text/sass" scoped></style>
